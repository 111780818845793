// src/pages/BlogOnePage.js
import React from 'react';

const BlogOnePage = () => {
  // List of photo names
  const photoNames = [
    'dj-metatron-loops-of-infinity-a-rave-loveletter-Cover-Art.webp',
    'dj-healer-nothing-2-loose-Cover-Art.webp',
    'the-ironsides-changing-light-Cover-Art.webp',
    'todd-terje-its-album-time-Cover-Art.webp',
    'ian-pooley-since-then-Cover-Art.webp',
    'g-flame-i-want-you-Cover-Art.webp',
    'moacir-santos-saudade-Cover-Art.webp',
    'jon-lucien-rashida-Cover-Art.webp',
    // 'black-midi-hellfire-Cover-Art.webp',
    'close-counters-soulacoasta-ii-Cover-Art.webp',
    'trusme-working-nights-Cover-Art.webp',
    'rick-the-godson-wilhite-my-voyage-Cover-Art.webp',
    'boris-with-merzbow-klatter-Cover-Art.webp',
    'visit-venus-music-for-space-tourism-vol-1-Cover-Art.webp',
    'k_d-1995-Cover-Art.webp',
    'king-gizzard-and-the-lizard-wizard-petrodragonic-apocalypse-or-dawn-of-eternal-night-an-annihilation-of-planet-earth-and-the-beginning-of-merciless-damnation-Cover-Art.webp',
    // 'justice-†-Cover-Art.webp',
    // 'mr-flash-sonic-crusader-Cover-Art.webp',
    'molecule-nazare-Cover-Art.webp',
    'boston-bun-we-got-soul-Cover-Art.webp',
    // 'carrtoons_saturday_night.jpg',
    'the-salsoul-orchestra-magic-journey-Cover-Art.webp',
    // 'bogdan-raczynski-addle-Cover-Art.webp',
    'eli-escobar-the-beach-album-Cover-Art.webp',
    // 'sebastian-tropic-Cover-Art.webp',
    'ensemble-intercontemporain-pierre-boulez-verklarte-nacht-op-4-suite-op-29-Cover-Art.webp',
    // 'peter-brown-do-you-wanna-get-funky-with-me-Cover-Art.webp',
    'hareton-salvanini-s_p-73-Cover-Art.webp',
    'tech-support-that-record-with-friends-on-it-Cover-Art.webp',
    'various-artists-chungking-express-Cover-Art.webp',
    'jeff-mills-skin-deep-ep-Cover-Art.webp',
    // 'lakecia-benjamin-phoenix-Cover-Art.webp',
    'emahoy-tsegue-maryam-guebrou-jerusalem-Cover-Art.webp',
    // 'ohio-drift-of-summer-Cover-Art.webp',
    'gothic-voices-christopher-page-emma-kirkby-a-feather-on-the-breath-of-god-Cover-Art.webp',
    // 'andy-ash-carnival-de-lspirit-Cover-Art.webp',
    'ishmael-ensemble-visions-of-light-Cover-Art.webp',
    // 'leland-whitty-anyhow-Cover-Art.webp',
    'trankilou-escalope-de-dingue-ep-Cover-Art.webp',
    'adrianne-lenker-songs-Cover-Art.webp',
    // 'sufjan-stevens-convocations-Cover-Art.webp',
    'azymuth-light-as-a-feather-Cover-Art.webp',
    'biddu-orchestra-biddu-orchestra-Cover-Art.webp',
    'daedelus-righteous-fists-of-harmony-Cover-Art.webp',
    'johann-johannsson-theatre-of-voices-paul-hillier-and-american-contemporary-music-ensemble-drone-mass-Cover-Art.webp',
    '武部聡志-satoshi-takebe-コクリコ坂から-オリジナルサウンドトラック-kokuriko-zaka-kara-Cover-Art.webp',
    'danger-mouse-and-karen-o-lux-prima-Cover-Art.webp',
    'kaelin-ellis-the-funk-will-prevail-Cover-Art.webp',
    'massive-attack-mezzanine-Cover-Art.webp',
    'tom-misch-and-yussef-dayes-what-kinda-music-Cover-Art.webp',
    'mezigue-le-meilleur-titre-pour-un-album-Cover-Art.webp',
    'branka-parlic-metamorphosis-Cover-Art.webp',
    'ελενη-καραΐνδρου-eleni-karaindrou-kim-kashkashian-ulysses-gaze-Cover-Art.webp',
    'mr-oizo-and-phra-voila-Cover-Art.webp',
    'frank-zappa-hot-rats-Cover-Art.webp',
    'boris-with-merzbow-現象-gensho-Cover-Art.webp',
    'ginger-breaker-pumping-the-tires-of-hell-Cover-Art.webp',
    'the-velvet-underground-and-nico-the-velvet-underground-and-nico-Cover-Art.webp',
    // 'various-artists-putumayo-presents-african-cafe-Cover-Art.webp',
    'poison-girl-friend-melting-moment-Cover-Art.webp',
    'machine-girl-because-im-young-arrogant-and-hate-everything-you-stand-for-Cover-Art.webp',
    // 'smerz-have-fun-Cover-Art.webp',
    // 'siriusmo-mosaik-Cover-Art.webp',
    'chico-buarque-os-saltimbancos-Cover-Art.webp',
    'philip-glass-glassworks-Cover-Art.webp',
    // 'etienne-de-crecy-super-discount-Cover-Art.webp',
    'kruder-dorfmeister-the-kandd-sessions™-Cover-Art.webp',
    // 'logic-the-incredible-true-story-Cover-Art.webp',
    'nujabes-modal-soul-Cover-Art.webp',
    'burial-four-tet-thom-yorke-ego-mirror-Cover-Art.webp',
    // 'ミラクルミュージカル-miracle-musical-hawaii-part-ii-Cover-Art.webp',
    'tedeschi-trucks-band-signs-Cover-Art.webp',
    'robert-ashley-thomas-buckner-sam-ashley-jacqueline-humbert-amy-x-neuburg-joan-la-barbara-adam-klein-improvement-Cover-Art.webp',
    'poppy-music-to-scream-to-Cover-Art.webp',
    'king-gizzard-and-the-lizard-wizard-infest-the-rats-nest-Cover-Art.webp',
    // 'no-vacation-summer-break-mixtape-Cover-Art.webp',
    // 'ミドリ-midori-あらためまして、はじめまして、ミドリです。-aratamemashite-hajimemashite-midori-desu-Cover-Art.webp',
    'the-pharcyde-bizarre-ride-ii-the-pharcyde-Cover-Art.webp',
    // 'coins-daft-science-Cover-Art.webp',
    'dj-falcon-hello-my-name-is-dj-falcon-Cover-Art.webp',
    // 'ゆらゆら帝国-yura-yura-teikoku-hollow-me-Cover-Art.webp',
    'woody-goss-trio-a-very-vulfy-christmas-Cover-Art.webp',
    // 'laurie-anderson-and-kronos-quartet-landfall-Cover-Art.webp',
    'ta-ku-25-nights-for-nujabes-Cover-Art.webp',
    'rei-harakami-red-curb-Cover-Art.webp',
    'parcels-day-night-Cover-Art.webp',
    // 'partiboi69-mutual-pleasure-002-Cover-Art.webp',
    'chilly-gonzales-solo-piano-Cover-Art.webp',
    // 'pacific-rat-temple-band-tan-kim-boa-paradise-Cover-Art.webp',
    'badbadnotgood-talk-memory-Cover-Art.webp',
    // 'shot_1.jpeg',
    'dj-healer-nothing-2-loose-Cover-Art.webp',
    'motorbass-pansoul-Cover-Art.webp',
    // 'olafur-arnalds-latenighttales-Cover-Art.webp',
    'gas-pop-Cover-Art.webp',
    'daft-punk-random-access-memories-Cover-Art.webp',
    'giant-swan-giant-swan-Cover-Art.webp',
    // 'sebastian-thirst-Cover-Art.webp',
    // 'lingua-ignota-sinner-get-ready-Cover-Art.webp',
    'philip-glass-north-star-Cover-Art.webp',
    'the-philip-glass-ensemble-michael-riesman-dancepieces-Cover-Art.webp',
    'philip-glass-koyaanisqatsi-Cover-Art.webp',
    // 'm_o_o_n-demos-2014-2017-Cover-Art.webp',
    // 'feist-let-it-die-Cover-Art.webp',
    'om-advaitic-songs-Cover-Art.webp',
    'mr-oizo-pharmacist-Cover-Art.webp',
    // 'flying-lotus-yasuke-Cover-Art.webp',
    // 'gigi-masin-wind-Cover-Art.webp',
    // 'gaspard-auge-escapades-Cover-Art.webp',
    // 'olafur-arnalds-re_member-Cover-Art.webp',
    'kinny-and-horne-forgetting-to-remember-Cover-Art.webp',
    'om-conference-of-the-birds-Cover-Art.webp',
    'om-pilgrimage-Cover-Art.webp',
    // 'masonna-freak-out-electrolyze-Cover-Art.webp',
    'masonna-spectrum-ripper-Cover-Art.webp',
    'edan-beauty-and-the-beat-Cover-Art.webp',
    // 'men-i-trust-men-i-trust-Cover-Art.webp',
    'le-knight-club-crydamoure-presents-waves-Cover-Art.webp',
    // 'clairo-sling-Cover-Art.webp',
    'plus-tech-squeeze-box-cartooom-Cover-Art.webp',
    'doopees-doopee-time-Cover-Art.webp',
    // 'against-all-logic-2017-2019-Cover-Art.webp',
    // 'against-all-logic-2012-2017-Cover-Art.webp',
    'kitty-craft-beats-and-breaks-from-the-flower-patch-Cover-Art.webp',
    'olafur-arnalds-and-alice-sara-ott-the-chopin-project-Cover-Art.webp',
    // 'aphex-twin-drukqs-Cover-Art.webp',
    // 'taylor-swift-folklore-Cover-Art.webp',
    // 'bogdan-raczynski-samurai-math-beats-Cover-Art.webp',
    // 'vulfpeck-the-joy-of-music-the-job-of-real-estate-Cover-Art.webp',
    // 'bruce-haack-haackula-Cover-Art.webp',
    // 'mr-flash-a-bass-day-radar-rider-f_i_s_t-Cover-Art.webp',
    'jurassic-5-quality-control-Cover-Art.webp',
    'mr-scruff-ninja-tuna-Cover-Art.webp',
    'queen-a-night-at-the-opera-Cover-Art.webp',
    // 'olivia-rodrigo-sour-Cover-Art.webp',
    // 'irena-jarocka-gondolierzy-znad-wisly-Cover-Art.webp',
    'badbadnotgood-late-night-tales-Cover-Art.webp',
    'floating-points-pharoah-sanders-and-the-london-symphony-orchestra-promises-Cover-Art.webp',
    // 'jiro-inagaki-and-his-soul-media-dock-of-my-mind-Cover-Art.webp',
    // 'the-book-club-friendo-Cover-Art.webp',
    // 'kendrick-lamar-damn-Cover-Art.webp',
    // 'porter-robinson-nurture-Cover-Art.webp',
    'vulfpeck-live-at-madison-square-garden-Cover-Art.webp',
    // 'boy-pablo-wachito-rico-Cover-Art.webp',
    // 'drugdealer-the-end-of-comedy-Cover-Art.webp',
    'sophie-oil-of-every-pearls-un-insides-Cover-Art.webp',
    'the-chemical-brothers-no-geography-Cover-Art.webp',
    // 'el-michels-affair-enter-the-37th-chamber-Cover-Art.webp',
    'candy-claws-ceres-and-calypso-in-the-deep-time-Cover-Art.webp',
    // '공중도덕-gongjoong-doduk-공중도덕-gongjoong-doduk-Cover-Art.webp',
    '공중도둑-mid-air-thief-무너지기-crumbling-Cover-Art.webp',
    'lcd-soundsystem-american-dream-Cover-Art.webp',
    'pink-floyd-meddle-Cover-Art.webp',
    'doelen-ensemble-palimpsest-Cover-Art.webp',
    // 'brockhampton-roadrunner-new-light-new-machine-Cover-Art.webp',
    'piano-ensemble-canto-ostinato-Cover-Art.webp',
    'l-imperatrice-tako-tsubo-Cover-Art.webp',
    'seattle-symphony-ludovic-morlot-become-ocean-Cover-Art.webp',
    'ictus-in-c-Cover-Art.webp',
    'madlib-sound-ancestors-Cover-Art.webp',
    'st-vincent-actor-Cover-Art.webp',
    'philip-glass-mishima-Cover-Art.webp',
    'kronos-quartet-dracula-Cover-Art.webp',
    'ensemble-signal-brad-lubman-music-for-18-musicians-Cover-Art.webp',
    // 'lingua-ignota-agnus-dei-Cover-Art.webp',
    // 'duke-dumont-the-dominion-dubs-ep-Cover-Art.webp',
    'machine-girl-gemini-Cover-Art.webp',
    'sunn-o-life-metal-Cover-Art.webp',
    'a-tribe-called-quest-peoples-instinctive-travels-and-the-paths-of-rhythm-Cover-Art.webp',
    'cortex-troupeau-bleu-Cover-Art.webp',
    // 'clown-core-van-Cover-Art.webp',
    'kutiman-6am-Cover-Art.webp',
    // 'moondog-hart-songs-Cover-Art.webp',
    // 'the-alchemist-rappers-best-friend-5-an-instrumental-series-Cover-Art.webp',
    'leya-flood-dream-Cover-Art.webp',
    'lcd-soundsystem-sound-of-silver-Cover-Art.webp',
    'mac-demarco-salad-days-Cover-Art.webp',
    'punch-brothers-the-phosphorescent-blues-Cover-Art.webp',
    'machine-girl-wlfgrl-Cover-Art.webp',
    'lingua-ignota-caligula-Cover-Art.webp',
    'daft-punk-tron-legacy-Cover-Art.webp',
    'daft-punk-alive-2007-Cover-Art.webp',
    'daft-punk-alive-1997-Cover-Art.webp',
    'daft-punk-human-after-all-Cover-Art.webp',
    'daft-punk-homework-Cover-Art.webp',
    'daft-punk-discovery-Cover-Art.webp',
    // 'tove-lo-blue-lips-lady-wood-phase-ii-Cover-Art.webp',
    'swum-a-lofi-xmas-Cover-Art.webp',
    'childish-gambino-awaken-my-love-Cover-Art.webp',
    'eloise-this-thing-called-living-Cover-Art.webp',
    'air-moon-safari-Cover-Art.webp',
    'the-avalanches-since-i-left-you-Cover-Art.webp',
    'the-four-seasons-the-genuine-imitation-life-gazette-Cover-Art.webp',
    'vulfpeck-thrill-of-the-arts-Cover-Art.webp',
    'alex-g-rules-Cover-Art.webp',
    'jessie-ware-whats-your-pleasure-Cover-Art.webp',
    'brad-mehldau-largo-Cover-Art.webp',
    // 'lettuce-crush-Cover-Art.webp',
    'j-dilla-donuts-Cover-Art.webp',
    // 'david-peacock-and-augustine-mayuga-gonzales-undertale-piano-collections-Cover-Art.webp',
    // 'john-tropea-to-touch-you-again-Cover-Art.webp',
    'morabeza-tobacco-morabeza-tobacco-Cover-Art.webp',
    // 'sola-sola-Cover-Art.webp',
    // 'jiro-inagaki-and-his-soul-media-head-rock-Cover-Art.webp',
    
    // Add all photo names here
  ];

  return (
    <div>
      <div className="limit_width_wide">
      <div style={{ whiteSpace: 'pre-line' }}>
      <p>
        This blogpost was initially made to track my album listning on rateyourmusic.com, which I had begun back in early 2021.
        Some months have been more or less active than others and I've had stretches where I've been good and bad about logging my listening.
        This was a display page for those records, with the top being more recent listens and the bottom older. As I update my website, this page will be altered;
        the below list will be albums I've listened to that I would recommend to others – not exclusively my favorites but a bit of a broader list.
        I hope that this can be a good descriptor of my musical life via RYM imaging. I might try to maintain a more full list at the bottom of this page, or 
        commented out in the source code.
  </p>
  {'\n'}{'\n'}
  </div>
        <div style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fill, minmax(200px, 1fr))', gap: '10px' }}>
          {photoNames.map((photoName, index) => (
            <div key={index} style={{ width: '100%' }}>
              <img src={`/blog_media/rym_blog_media/${photoName}`} alt={`Cover ${index + 1}`} style={{ width: '100%', height: 'auto' }} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );  
};

export default BlogOnePage;
