// src/pages/CocktailBlogPage.js
import React from 'react';

const CocktailBlogPage = () => {
  return (
    <div>
      <div class="limit_width">
        <div style={{ whiteSpace: 'pre-line' }}>
      <p>
      <h1>On Cocktails</h1>
		{'\n'}
		This blog post will be a discussion of cocktails as well as a place where I update what I have been enjoying lately. 
		A cocktail is a mixed drink with two or more ingredients in which at least one is alcoholic. They seem to be a near 
		universal endeavor, which is a factor of cultures fermenting many thousands of years ago, whether by accident or 
		intentionally. The first cocktail is often said to be the Sazerac, cognac and sugar over ice with some Peychaud’s 
		Bitters and typically an absinthe rinse made by Aaron Bird in the late 1800s in New Orleans. The earliest alcoholic 
		beverage may differ by sources but I found a record of a fermented rice, honey, and grape (or hawthorn fruit) 
		beverage dated around 7000-6600 BCE.
		{'\n'}{'\n'}
    <img src="/blog_media/cocktail_blog_media/008.jpeg" alt="photo of a sazerac cocktail" style={{ display: 'block', width: '100%' }} />
		{'\n'}{'\n'}
		There is obviously a rich history of alcoholic beverages, both stand alone and mixed, that I will try to elude to. 
		Hopefully you, the reader, can use this as a jumping off point. Below is a link to my personal menu that I will 
		version over time, and I will try to discuss all the cocktails mentioned to a reasonable extent. I also will likely 
		update restaurant's and bar's cocktail menus on this page which I think are fun to collect and interesting to look 
		through.
		{'\n'}{'\n'}
		<a href="./cocktails_links.html">Relevant Links</a>
		{'\n'}{'\n'}
		Note that while specific measurements, ingredients, glassware, and even brands will be named below, cocktails 
		should be enjoyed in whatever way you enjoy them with a respect for their history. There are many different 
		measurements, ingredients, glassware, and brands that differ and are equally valid.
		{'\n'}{'\n'}
		Some terminology and techniques to begin.
		{'\n'}{'\n'}
		Two seriously important items when making cocktails are always measure your ingredients, and always use fresh 
		lemon and fresh lime juice. I like to buy quite a few lemons and limes, and upon juicing them all, store any 
		extra in glass bottles in the freezer. Frozen lemon and lime juice will last around three months. Despite the 
		massive color difference between lemons and limes, their flavor profiles are actually quite similar. In a pinch, 
		most recipes can switch between lemon and lime juice.
		{'\n'}{'\n'}
		Shaking a cocktail is when ingredients are added to a shaker tin with ice, and.. well.. shaken. Large ice will 
		dilute less than small ice. The ice going through the ingredients will also disrupt the liquid. For example 
		large ice is typically used with frothed egg white because small ice will cut down on the froth more.
		{'\n'}{'\n'}
		Stirring a cocktail is when you use a bar spoon to stir ingredients in a mixing glass with ice. This will dilute 
		differently and create a different texture than shaking. I typically have seen cocktails without citrus stirred, 
		and with shaken. This is because ice shaken through the cocktail will tame the tang from the citrus. Whether this 
		is proven or not I do not know.
		{'\n'}{'\n'}
		Dry shaking is the act of shaking without ice. I have frequently seen it used when egg white is involved in a 
		cocktail. This froths the egg white. It is typically preceded or followed by a shake with ice.
		{'\n'}{'\n'}
		Most people will advise measuring out cheaper ingredients first, so that if a mistake is made and the cocktail 
		can't be salvaged, one need not pour out a nice spirit. In that same sphere, it is advised when using egg, to 
		crack the egg white first and in a separate container, so that if the yolk is broken and spilled in on accident 
		it does not ruin ingredients already poured. For vegans, some recommend using Aquafaba (chickpea juice) as a 
		substitute for the egg white, although I have never tried this. Some instances of simple syrup can be varied 
		in measurements depending on preference, and the breadth of variance is up to personal taste. For example, 
		an Amaretto Sour calling for a teaspoon of simple syrup maybe can be ignored, whereas in a Fernet Sour I would 
		not recommend reducing the amount of syrup. This is something that you can determine after spending time with 
		these cocktails.
		{'\n'}{'\n'}
		Most of the time simple syrup refers to a mixture of sugar and water in equal parts, heated and stirred until 
		all the sugar is dissolved, the mixture is clear, and has not boiled (boiling will begin to caramalize the 
		sugar). There are some instances where a rich syrup may be called for, which is two parts sugar to one part 
		water. I have not had any trouble always using a simple syrup. I would encourage everyone to make their own 
		syrup as it is significantly cheaper and I have seen odd ingredients included in store bought syrups (i.e. 
		chemicals to help preserve it). Homemade simple syrup will last for one month in the fridge.
		{'\n'}{'\n'}
		I think that people in their mid twenties should know at least enough to make two classic cocktails and maybe 
		one fun cocktail. One should buy shaking tins, a Hawthorne and fine mesh strainer, maybe a muddler, and a
		jigger (that can measure 1, 2, and also 1/2, 3/4, and 1 and 1/2 ounces). Basic bottles to get started would be a 
		Bourbon Whiskey (Bulleit), a Gin (Beefeater), a Vodka (Wodka), and then I would recommend bottles of Aperol and 
		Campari. Branching out one should get bottles that interest them. Kahlua for the popular Espresso Martini, 
		Chartreuse and Maraschino Liqueur (Luxardo) for the Last Word and variants, Amaro Nonino for the Paper Plane, 
		etc. Expensive bottles of spirit typically are not mixed with and are drunk straight, but again, this is personal 
		preference.
		{'\n'}{'\n'}
		As far as glassware goes, I would encourage beginning with four tall glasses (Martini, Nick &amp; Nora, or 
		Coupe) and four short glasses (Double Rocks Glass). This for the most part will cover all cocktail needs. 
		<a href="https://www.cocktailkingdom.com/all-glassware/glassware" target="_blank" rel="noopener noreferrer">Cocktail Kingdom</a> 
		seems to have nice affordable glassware, and seems to have a consistent stock (you will inevitably break a 
		few glasses and it is nice to be able to get the same style). From here, one can begin to get more tall 
		lasses, aperitif glasses, or whatever they feel they need. Another good addition is a Collins glass which 
		you would typically see from a Gin and Tonic. It is also notable that thrift stores and pawn shops can have 
		nice glasses for significantly reduced prices.
		{'\n'}{'\n'}
		In the same fashion that sauces often derive from the five French mother sauces, cocktails also have basic 
		formulas with variable ingredients and general measurements. Some common ones are Sours, Spritzes, Spirit 
		Forward, and Highball though there are more. I will not delve too deeply into the families of cocktails but 
		I will try to mention them when I remember or find it relevant.
		{'\n'}{'\n'}
		Let's get to the recipes.
		{'\n'}{'\n'}
		<h1 style={{ textAlign: 'center' }}>The Pisco Sour</h1>
		{'\n'}{'\n'}
    <img src="/blog_media/cocktail_blog_media/009.jpeg" alt="photo of a pisco sour" style={{ display: 'block', width: '50%', marginLeft: 'auto', marginRight: 'auto' }} />
		{'\n'}{'\n'}
		The Pisco Sour is a type of Sour; an interesting cocktail that CM had recommended I get while I lived in DC. I got 
		it from a restaurant called Chicken and Whiskey that has an excellent speakeasy whiskey bar hidden in the back. It 
		is a Peruvian cocktail that is named for its base liquor, Pisco. Historians estimate the cocktail to have been 
		made in the 1920s in Lima, the capital of Peru, by Victor Vaughen Morris, and later was modified to include 
		Angostura Bitters and egg white by Mario Bruiget. Chile and Peru both claim the drink as their national drink 
		and assert their invention of Pisco. Pisco itself is a brandy, made by Spanish settlers in the 16th century as 
		an alternative to orujo, a pomace brandy from Spain. The name Pisco could derive from the Peruvian town of Pisco, 
		located in the valley of Pisco, on the Pisco river. Pisco is used as shorthand for "Aguardiente de Pisco”, 
		literally “firewater from Pisco”. Pisco’s ABV is typically around 40%.
		{'\n'}{'\n'}&emsp;
		2 ounces Pisco{'\n'}&emsp;
		1 ounce lime juice{'\n'}&emsp;
		1/2 ounce simple syrup{'\n'}&emsp;
		1 egg white{'\n'}&emsp;
		Garnish with Angostura bitters
		{'\n'}{'\n'}
		All ingredients ingredients are to be dry shaken without ice, then shaken with ice, strained into a 
		Nick &amp; Nora glass, and garnished with a few drops of the bitters.
		{'\n'}{'\n'}
		<h1 style={{ textAlign: 'center' }}>The Last Word</h1>
		{'\n'}{'\n'}
    <img src="/blog_media/cocktail_blog_media/010_alt.jpeg" alt="photo of a last word" style={{ display: 'block', width: '50%', marginLeft: 'auto', marginRight: 'auto' }} />
		{'\n'}{'\n'}
		The Last Word is a cocktail that DJ Suan had introduced me to. DJ Suan was the bartender at The 
		Patterson Mansion in DC, a private bar that I had the pleasure of frequenting in 2020. He explained 
		much of the history of the cocktail as well as the history of its main liqueur, Green Chartreuse, which 
		I will attempt to re-explain here.
		{'\n'}{'\n'}
		The Last Word was a cocktail served in the 1920s in Detroit and later New York, but was likely served 
		earlier. The first appearance of it in a book was Ted Saucier’s 1951 Bottom’s Up!. The name may be in 
		reference to Frank Fogarty, who introduced it to New York. Fogarty was a sort of stand-up comedian, and 
		some think that was the source of the cocktail’s name. As Chartreuse and Maraschino Liqueur became 
		difficult to find, the cocktail fell out of popularity sometime after World War II. It was reintroduced 
		in Seattle’s Zig Zag Cafe in 2004 by Murray Stenson who found it in Saucier’s book, and has since regained 
		significant popularity and spawned many variations.
		{'\n'}{'\n'}
		The cocktail is notable for its ingredients being strong and in equal parts, which manage to balance 
		well when together. The most notable ingredient is Green Chartreuse, for which the color is named after. This spirit is an herbal liqueur, made initially as an elixir of long life. It was developed from a 17th century recipe and perfected in the 18th century by the Carthusian Monks in the Chartreuse Mountains. It contains 130 herbs and botanicals whose names, quantity, and distillation process is a closely guarded secret known only to a few monks. It has a sister liqueur called Yellow Chartreuse, both of whom have an ABV around 50%.
		{'\n'}{'\n'}
		This cocktail has become a favorite of mine. I love the story, ingredients, flavor, and the many variants spawned from it.
		{'\n'}{'\n'}
		&emsp;3/4 ounce Green Chartreuse
		{'\n'}&emsp;3/4 ounce Gin
		{'\n'}&emsp;3/4 ounce Maraschino Liqueur
		{'\n'}&emsp;3/4 ounce Lime Juice
		{'\n'}&emsp;Garnish with Cherry.
		{'\n'}{'\n'}
		All ingredients are to be shaken over ice, and strained into a coupe glass, garnished with a cherry.
		{'\n'}{'\n'}
		I prefer Luxardo Maraschino Liqueur and Fabbri Amarena Cherries (as recommended by DJ Suan).
		{'\n'}{'\n'}
		<h1 style={{ textAlign: 'center' }}>The (Dirty) Martini</h1>
		{'\n'}{'\n'}
    <img src="/blog_media/cocktail_blog_media/011.jpeg" alt="photo of a dirty martini" style={{ display: 'block', width: '50%', marginLeft: 'auto', marginRight: 'auto' }} />
		{'\n'}{'\n'}
		The Martini is a complex drink. I will not attempt to be an expert. It is typically made with gin and vermouth, shaken or stirred, gin possibly substituted for vodka, and can be ordered more or less “dirty”, whereas no specification means “wet”. A dirty Martini begins to include olive brine, and can in some cases, replace the vermouth entirely with brine. I will say that a dirty gin martini is more unusual than a dirty vodka martini, as vodka is more or less flavorless so the strength of brine can shine in the drink, whereas gin’s profile may clash or be overwhelmed by brine.
		{'\n'}{'\n'}
		In 1922, the martini had reached its typical form of London dry gin and dry vermouth with a 2:1 ratio. The origin is unknown and the name may derive from a brand of vermouth called Martini, though, others argue that it evolved from a cocktail called the Martinez served in the early 1860s at the Occidental Hotel in San Francisco (named because the hotel would have a ferry to go to Martinez, California). With prohibition, the Martini rose in popularity because Gin was relatively easy to smuggle and the ingredients were few. Many cocktails are called “Martinis” but are named simply because they are served in a martini glass. An Appletini or Espresso Martini are not variations on the Martini.
		{'\n'}{'\n'}
		I prefer a very dirty vodka martini, a favorite of my friend JE, who when ordering once said, “if you think it’s too dirty, it’s not dirty enough”. My palette is not refined enough to have a preference between shaken or stirred. Despite this, I only ever order shaken.
		{'\n'}{'\n'}
		&emsp;2 and 1/2 ounces Vodka
		{'\n'}&emsp;1 and 1/2 ounces Olive Brine
		{'\n'}&emsp;Garnish with two Olives
		{'\n'}{'\n'}
		All ingredients are to be shaken over ice, and strained into a martini glass, garnished with olives.
		{'\n'}{'\n'}
		I prefer Wodka Vodka (as recommended by DJ Suan).
		{'\n'}{'\n'}
		<h1 style={{ textAlign: 'center' }}>The Paper Plane</h1>
		{'\n'}{'\n'}
    <img src="/blog_media/cocktail_blog_media/012.jpeg" alt="photo of a paper plane" style={{ display: 'block', width: '50%', marginLeft: 'auto', marginRight: 'auto' }} />
		{'\n'}{'\n'}
		This is a modern cocktail, developed around 2007 by Sasha Petraske and Sam Ross of the famed Milk &amp; Honey cocktail bar in New York. It is a rift on The Last Word, with equal parts of a base liquor, main liqueur, sweetening liqueur, and citrus. Respectively, The Last Word is Gin, Green Chartreuse, Maraschino Liqueur, and Lime, whereas the Paper Plane is Whiskey, Amaro Nonino, Aperol, and Lemon. The name supposedly comes from MIA’s song “Paper Planes”, a guilty pleasure of Petraske.
		{'\n'}{'\n'}
		The interesting ingredient here is Amaro Nonino with an ABV of 35%; a bittersweet liqueur from the brand Nonino Grappa. This brand was founded by Orazio Nonino, in the Friuli region of northeastern Italy, in 1897. Still to this day it is run by the Nonino family. The company mainly produces Grappa, a grape based pomace brandy, and has won many awards. Oddly enough it instituted the International Nonino Prize, an award to encourage the preservation of Friulian traditions, which has since become a prestigious literary prize.
		{'\n'}{'\n'}
		&emsp;3/4 ounce Whiskey (Bourbon)
		{'\n'}&emsp;3/4 ounce Amaro Nonino
		{'\n'}&emsp;3/4 ounce Aperol
		{'\n'}&emsp;3/4 ounce Lemon Juice
		{'\n'}&emsp;Optionally garnish with lemon peel or a small paper plane.
{'\n'}{'\n'}
All ingredients are to be shaken over ice, and strained into a coupe glass, with optional garnishes of lemon peel or a small paper plane.
		{'\n'}{'\n'}
		<h1 style={{ textAlign: 'center' }}>The Fernet (Industry) Sour</h1>
		{'\n'}{'\n'}
    <img src="/blog_media/cocktail_blog_media/013.jpeg" alt="photo of a fernet sour" style={{ display: 'block', width: '50%', marginLeft: 'auto', marginRight: 'auto' }} />
		{'\n'}{'\n'}
		The Industry Sour is a modern cocktail made by Ted Kilgore at Taste in St. Louis in 2011. It’s name “industry” implies it is for people who work in the service industry, though I have read this was a name simply to encourage cocktail aficionados who wanted to be in the know. I personally think the name is an extension of Fernet being known as a “bartenders’ handshake”, i.e. an industry drink that bartenders would get from one another. Fernet is the most famous bartenders’ handshake, likely due to its intense flavor profile which requires a certain acquired taste. Here it mixes with the aggressive and herbal Chartreuse for a shockingly balanced and palatable cocktail.
		{'\n'}{'\n'}
		Fernet is a type of Italian Amaro that is very bitter and intense in taste, with an ABV of 39%. When someone says “Fernet” it’s reasonable to assume they are referring to Fernet-Branca, a brand of Fernet that has become synonymous with the name. This specific brand was made by Fratelli Branca in Milan in 1845, initially marketed as a cure for cholera and menstrual cramps. In 1907, the brand began to export to Argentina, and Fernet-Branca became increasingly popular there, to the point that another distillery was made in Buenos Aires, most popularly drunk there in a Fernando or Fernet and Coke (Fernet Con Coca), a cocktail of Fernet and Cola.
		{'\n'}{'\n'}
		I had initially thought this cocktail to be considered a variation on the Last Word, noting its equal parts format as well as inclusion of Chartreuse and an Amaro, but I now revoke that opinion, as the Amaro and Chartreuse function here as a combined base spirit, and the sweetener is syrup, as opposed to the Last Word make up which was discussed above. Though, if someone were to disagree and say that this is in fact a variation on the Last Word, I would not necessarily take a heavy stance to oppose them.
		{'\n'}{'\n'}
		&emsp;1 ounce Fernet
		{'\n'}&emsp;1 ounce Green Chartreuse
		{'\n'}&emsp;1 ounce Lime Juice
		{'\n'}&emsp;1 ounce Simple Syrup
		{'\n'}{'\n'}
		All ingredients are to be shaken over ice, and strained into a coupe glass. I have sometimes seen this garnished with a wheel of lime.
		{'\n'}{'\n'}
		<h1 style={{ textAlign: 'center' }}>The Caipirinha</h1>
		{'\n'}{'\n'}
    <img src="/blog_media/cocktail_blog_media/014.jpeg" alt="photo of a caipirinha" style={{ display: 'block', width: '50%', marginLeft: 'auto', marginRight: 'auto' }} />
		{'\n'}{'\n'}
		The Caipirinha is Brazil’s national cocktail, with vague origin, but said to have been made around 1918 from the Alentejo region of Portugal as a prescription for the Spanish Flu. Another account says it was made in the 19th century as a local drink in the Piracicaba region of São Paulo. The name derives from the word caipira, which refers to the countryside.
		{'\n'}{'\n'}
		The drink utilizes Cachaça, a fermented sugarcane spirit similar to Rum, popular in Brazil with an ABV typically over 40%. It is thought to have been made in the 16th century, when sugar cane was brought by Portuguese colonists to Brazil in the Madeira Islands. Like Rum, Cachaça has two types: one white and unaged, and the other aged to a yellow or gold color.
		{'\n'}{'\n'}
		&emsp;2 ounces Cachaça
		{'\n'}&emsp;1 Lime cut into wedges
		{'\n'}&emsp;2 teaspoons Sugar
		{'\n'}&emsp;Optionally garnish with Lime Wheel.
		{'\n'}{'\n'}
		The wedges and sugar are to be added to a shaking tin and muddled. The Cachaça is added, and then shaken. Serve without straining into a double rocks glass, with optional further lime garnish.
		{'\n'}{'\n'}
		<h1 style={{ textAlign: 'center' }}>The Division Bell</h1>
		{'\n'}{'\n'}
   		<img src="/blog_media/cocktail_blog_media/015.jpeg" alt="photo of a division bell" style={{ display: 'block', width: '50%', marginLeft: 'auto', marginRight: 'auto' }} />
		{'\n'}{'\n'}
		The Division Bell is a cocktail made by Phil Ward in New York for the opening of Mayaheul, a Mezcal focused cocktail bar in 2009. Ward has made a name for himself with the creation of several modern classic cocktails and his work at influential cocktail bars (Like Audrey Saunders's Pegu Club, or the famous Death &amp; Company).
		{'\n'}{'\n'}
		Mezcal is a spirit made with the agave plant, its name from Nahuatl’s word Mexcalli meaning “over-cooked agave”. Agave itself was a sacred plant in pre-Spanish Mexico, involved in rituals, mythology, and the economy. The myth behind the fermentation of agave is that a lightning bolt struck an agave plant, cooking and opening it, releasing the juice. The liquid is sometimes called “elixir of the gods”. These liquors were called aquardiente or “firewater”. The Spanish in their colonization had brought their own spirits, but upon running out turned to the agave plant. Mezcal can be dated by this to the 16th century.
		{'\n'}{'\n'}
		I cannot confirm with online sources, but it is said that Ward made this drink inspired by and as a variation of The Last Word. I also cannot confirm any particular source of the name. The measurements in my sources also vary, but the base is Mezcal with Aperol as an altering flavor and sweetener as well as Maraschino Liqueur as a sweetener. Lime juice is also added as a citrus component. I personally think it would be appropriate to call this cocktail a variation on The Last Word, but again, would not take a heavy stance to oppose someone who disagrees.
		{'\n'}{'\n'}
		&emsp;1 ounce Mezcal
		{'\n'}&emsp;3/4 ounce Aperol
		{'\n'}&emsp;1/2 ounce Maraschino Liqueur
		{'\n'}&emsp;3/4 ounce Lime Juice
		{'\n'}&emsp;Optionally wipe glass with oil from a Grapefruit peel.
		{'\n'}{'\n'}
		All ingredients are to be shaken over ice, and strained into a coupe glass. You can take a peel from a grapefruit and express those oils over the top of the cocktail.
		{'\n'}{'\n'}
		<h1 style={{ textAlign: 'center' }}>The Amaretto Sour</h1>
		{'\n'}{'\n'}
    	<img src="/blog_media/cocktail_blog_media/016.jpeg" alt="photo of an amaretto sour" style={{ display: 'block', width: '50%', marginLeft: 'auto', marginRight: 'auto' }} />
		{'\n'}{'\n'}
		The Amaretto Sour is from America in the 1970s. Nobody is certain who created it. It takes a form of a typical Sour style drink, showcasing Amaretto. Amaretto is a sweet Italian Liqueur, usually with an ABV around 25%. From Saronno, in the Lombardy region of Italy, it uses apricot kernels, almonds, or peach stones as flavoring. In some cases you’ll even see Amaretto added to coffee or used in cooking. Amaretto derives from Italian “Amaro” meaning bitter, though the heavily sweetened liqueur is not included in the Amaro family.
		{'\n'}{'\n'}
		The most popular brand Disaronno or di Saronno (literally from Saronno) insists the liqueur was made in 1525, when a church in Saronno "commissioned artist Bernardino Luini, one of Leonardo da Vinci's pupils, to paint its sanctuary with frescoes. As the church was dedicated to the Virgin Mary, Luini needed to depict the Madonna, but was in need of a model. He found his inspiration in a young widowed innkeeper. As a gift for him, the woman steeped apricot kernels in brandy, producing the first amaretto liqueur”. This excerpt is from Wikipedia.
		{'\n'}{'\n'}
		There are some instances where the cocktail exclusively utilizes Amaretto as a base liqueur, and others that split it with Bourbon. I will offer the recipe with Bourbon.
		{'\n'}{'\n'}
		&emsp;1 and 1/2 ounces Amaretto Liqueur
		{'\n'}&emsp;3/4 ounce Bourbon
		{'\n'}&emsp;1 ounce Lemon Juice
		{'\n'}&emsp;1 teaspoon of Simple Syrup
		{'\n'}&emsp;1/2 egg white
		{'\n'}&emsp;Garnish with Lemon Twist and Cherries.
		{'\n'}{'\n'}
		All ingredients are to be “dry” shaken without ice, then shaken with ice, strained into a double rocks glass over ice, and garnished with a lemon twist and cherries.
		{'\n'}{'\n'}
		<h1 style={{ textAlign: 'center' }}>The Kentucky Mule</h1>
		{'\n'}{'\n'}
    	<img src="/blog_media/cocktail_blog_media/017.jpeg" alt="photo of a kentucky mule" style={{ display: 'block', width: '50%', marginLeft: 'auto', marginRight: 'auto' }} />
		{'\n'}{'\n'}
		The Kentuck Mule is a variation on the Moscow Mule, and my preferred version. The Moscow Mule employs Vodka, Ginger Beer, and Lime Juice, and can be considered a style of Buck. One version of the origin of the Moscow Mule cites it was invented in 1941 in Manhattan at the Chatham Hotel in “Little Moscow”. The owner of Cock ’n’ Bull, a producer of Ginger Beer, was with John G Martin (President of Heublein Spirits) and Rudolph Kunett (President of Hublein’s Vodka division). They were eating and drinking and decided to mix Vodka with Ginger Beer and a splash of Lemon Juice. Hence the Moscow Mule was born.
		{'\n'}{'\n'}
		Often the drink is served in a copper mug with ice and a garnish of Lime. The mug is attributed to Martin, who in his marketing for the Vodka and Mule, had bartenders pose with a specialty copper mug, taking two polaroids, one to take, and one to leave at the bar. The drink has spawned many variations. I initially would drink the traditional Vodka variation, then progressed to the Mexican Mule (or Tequila Mule) which used Tequila. Eventually I settled on the Kentucky Mule, which is said to have been made in the 1940s, and uses Bourbon as the base spirit. Mules can be measured but are highly dependent on preference as well as type of Ginger Beer. I like to use Fever Tree for the flavor, and the small bottles I can use fully use without having any leftover.
		{'\n'}{'\n'}
		&emsp;2 ounces Bourbon
		{'\n'}&emsp;1/2 ounce Lime Juice
		{'\n'}&emsp;6 or more ounces of Ginger Beer
		{'\n'}&emsp;Garnish with Lime Wedge and Angostura Bitters
		{'\n'}{'\n'}
		All ingredients are to be added to a metal or glass mug, or a Collins glass, over ice. One should give a light stir to incorporate the ingredients. Garnish with a lime wedge and a few drops of Angostura Bitters. I have also seen a mint sprig used as garnish.
		{'\n'}{'\n'}
		<h1 style={{ textAlign: 'center' }}>The Deal Closer</h1>
		{'\n'}{'\n'}
    	<img src="/blog_media/cocktail_blog_media/018.jpeg" alt="photo of Apotheke's Deal Closer" style={{ display: 'block', width: '50%', marginLeft: 'auto', marginRight: 'auto' }} />
		{'\n'}{'\n'}
		The Deal Closer is a modern cocktail from the speakeasy cocktail bar Apotheke in New York, with a location in LA opened later in 2018. It is inspired by apothecaries and the idea of the medicinal cocktail. The Deal Closer itself is one of their simpler cocktails, though I will simplify further by omitting their use of “a dash of” vanilla infused Cognac, 1 cc of in house Libido Bitters, and recipe for sour mix which is a fancy phrase for citrus and simple syrup.
		{'\n'}{'\n'}
		&emsp;3 Cucumber Slices
		{'\n'}&emsp;1 Mint Sprig
		{'\n'}&emsp;1 ounces Vodka
		{'\n'}&emsp;1 ounce Horny Goat Weed Tea
		{'\n'}&emsp;1/2 ounce Lime Juice
		{'\n'}&emsp;1/2 ounce Simple Syrup
		{'\n'}&emsp;Garnish with Cucumber Slice and Mint Leaf
		{'\n'}{'\n'}
		The Mint Sprig and Cucumber Slices are to be added to the tin and muddled. Putting the Mint in first, underneath the Cucumber, to avoid bruising the Mint (which releases a bitter vegetable flavor). The rest of the ingredients are to be added and shaken over ice, strained into a coupe glass. Garnish with a Cucumber Slice, and optionally a single small Mint Leaf.
		{'\n'}{'\n'}
		<h1 style={{ textAlign: 'center' }}>The Aviation</h1>
		{'\n'}{'\n'}
    	<img src="/blog_media/cocktail_blog_media/019.jpeg" alt="photo of an Aviation" style={{ display: 'block', width: '50%', marginLeft: 'auto', marginRight: 'auto' }} />
		{'\n'}{'\n'}
		The Aviation is a classic cocktail created by Hugo Ensslin at New York’s Hotel Wallick in the early 20th century. One can consider it a Sour, using Maraschino Liqueur as a sweetener. The name derives from the cocktail’s use of Crème de Violette Liqueur, which gives the cocktail a pale sky-blue color and references the newfound air travel industry which at the time was a luxury of the rich.
		{'\n'}{'\n'}
		Crème de Violette is a liqueur (typically with ABV around 20%) which has natural or artificial violet flower flavoring, dated to early 19th century when it was served with dry vermouth or alone as a cordial. The difficulty in finding it in the United States has led to this cocktail being occasionally made without it despite the fact that it is a core ingredient.
		{'\n'}{'\n'}
		&emsp;2 ounces Gin
		{'\n'}&emsp;1/2 ounce Maraschino Liqueur
		{'\n'}&emsp;3/4 ounce Lemon Juice
		{'\n'}&emsp;1/4 ounce Crème de Violette
		{'\n'}&emsp;Garnish with Cherry
		{'\n'}{'\n'}
		All ingredients are to be shaken over ice, and strained into a coupe glass. Garnish with a cherry. I have also seen garnishes with a lemon peel.
		{'\n'}{'\n'}
		<h1 style={{ textAlign: 'center' }}>The Final Ward</h1>
		{'\n'}{'\n'}
    <img src="/blog_media/cocktail_blog_media/020.jpeg" alt="photo of the final ward" style={{ display: 'block', width: '50%', marginLeft: 'auto', marginRight: 'auto' }} />
		The Final Ward is a riff on The Last Word, invented by Phil Ward (mentioned above with The Division Bell), at some point in the 21st century. The name acknowledges The Last Word but also serves well to include Ward’s name. I have seen or been told that sometimes Yellow Chartreuse is used here as opposed to Green, but I can find no reference online that supports this claim. I have also, in a pinch, used Bourbon as opposed to Rye to some success.
		{'\n'}{'\n'}
		&emsp;3/4 ounce Rye Whiskey
		{'\n'}&emsp;3/4 ounce Green Chartreuse
		{'\n'}&emsp;3/4 ounce Maraschino Liqueur
		{'\n'}&emsp;3/4 ounce Lemon Juice
		{'\n'}{'\n'}
		All ingredients are to be shaken over ice, and strained into a coupe glass.
		{'\n'}{'\n'}
		<h1 style={{ textAlign: 'center' }}>The Gin Basil Smash</h1>
		{'\n'}{'\n'}
<img src="/blog_media/cocktail_blog_media/021.jpeg" alt="photo of the gin basil smash" style={{ display: 'block', width: '50%', marginLeft: 'auto', marginRight: 'auto' }} />
		{'\n'}{'\n'}
		The Gin Basil Smash is a modern classic cocktail, invented by Jörg Meyer in 2008, in Hamburg Germany. One source says it is a variation on the Whiskey Smash from 1887, though I personally would consider it a general cocktail in the Smash family, which typically have some base spirit, sugar, citrus, and mint, served over crushed ice. The Julep is probably the most well known Smash. It seems to me this is a favorite off my menu; the simplicity of this cocktail makes it incredibly palatable and tasty. Meyer says in an interview that the inclusion of the basil stems is important for the color of the cocktail, though overmuddling, like with mint, can affect the color and taste negatively.
		{'\n'}{'\n'}
		&emsp;1 Basil Sprig (10~ Leaves)
		{'\n'}&emsp;2 ounces Gin
		{'\n'}&emsp;1 ounce Simple Syrup
		{'\n'}&emsp;1 ounce Lemon Juice
		{'\n'}&emsp;Garnish with Basil Sprig
		{'\n'}{'\n'}
		Muddle the basil lightly in a tin, then add all ingredients and shake with ice. Strain into a Collins glass and add crushed ice, garnishing with a sprig of basil.
		{'\n'}{'\n'}
		<h1 style={{ textAlign: 'center' }}>The Toronto</h1>
		{'\n'}{'\n'}
<img src="/blog_media/cocktail_blog_media/022.jpeg" alt="photo of the gin basil smash" style={{ display: 'block', width: '50%', marginLeft: 'auto', marginRight: 'auto' }} />
		{'\n'}{'\n'}
		The Toronto is a cocktail that adds Fernet to the classic Old Fashioned. First cited in Robert Vermeire’s 1922 book Cocktails: How to Mix Them, he said it was so named because the cocktail was very enjoyed by those from Toronto. At this time though, the import of alcoholic beverages in Ontario was banned. Therefore, it is unclear whether or not the drink was popular in Toronto prior to the prohibition, or if the people from Toronto had enjoyed the cocktail at his bar in London.
		{'\n'}{'\n'}
		The Old Fashioned was one of the simpler and earlier cocktails, becoming more complex in the late 1800s including Orange Curaçao, Absinthe, or other liqueurs. This made bar patrons who wanted the original drink to ask for the pre-1850s style with simply Whiskey, Sugar, some Angostura Bitters, and maybe a dash of water. Hence it was named The Old Fashioned.
		{'\n'}{'\n'}
		The Toronto calls for Canadian Whisky, although I have had no issues substituting an American Whiskey, typically Rye. Some recipes will have more or less Fernet and accordingly more or less Whiskey. The decision to use a Simple Syrup or Raw Sugar is also personal preference, though it could be considered more traditional to use Raw Sugar. I use Simple Syrup, so as to mix in the sweetness evenly rather than having the cocktail become sweeter over time (as the bottom has undissolved sugar, and as the ice dilutes the drink more).
		{'\n'}{'\n'}
		&emsp;2 ounces Rye Whiskey
		{'\n'}&emsp;1/4 ounce Fernet
		{'\n'}&emsp;1/4 ounce Simple Syrup
		{'\n'}&emsp;2 Dashes Angostura Bitters
		{'\n'}{'\n'}
All ingredients are to be stirred over ice, and strained into a double rocks glass over ice or served up (without ice) in a coupe glass . Optional garnishes can include an orange peel or a cherry.
		{'\n'}{'\n'}
		<h1 style={{ textAlign: 'center' }}>The Espresso Martini</h1>
		{'\n'}{'\n'}
<img src="/blog_media/cocktail_blog_media/023.jpeg" alt="photo of the gin basil smash" style={{ display: 'block', width: '50%', marginLeft: 'auto', marginRight: 'auto' }} />
		{'\n'}{'\n'}
		The Espresso Martini is a modern cocktail that has gained significant popularity over the past few years. Despite the name, it is not a variation on the Martini, but rather is served at times in a martini glass. The drink has varied origin stories, but the most popular is that it was made by Dick Bradsell in the late 1980s at Fred’s Club in London, for a young woman who asked for a drink that would, “wake me up and then fuck me up”. While the brand of Vodka or Coffee Liqueur can vary here, the most significant part of this cocktail is its actual inclusion of Espresso. I have used my Bialetti for Moka in lieu of Espresso to great success. I would encourage anyone reading this to purchase a Bialetti and nice whole Coffee Beans.
		{'\n'}{'\n'}
		There are different brands of Coffee Liqueur and I have not tried or used any other than Kahlúa, which is made by the Pernod Ricard company produced in Veracruz, Mexico. This liqueur includes rum and has an ABV of 20% – first produced by Pedro Domecq in 1936. Kahlúa is a Veracruz Nahuatl word and means “House of the Acolhua people”. It is made with arabica coffee beans grown in Veracruz and can even be used when baking. The first Coffee Liqueur may be Tia Maria, whose recipe dates back to the 17th century, but was not commercialized until the 1950s. Tia Maria was eventually bought by Pernod Ricardo in 2005, and was sold once more to Illva Saronno in 2009.
		{'\n'}{'\n'}
		Keep in mind with this recipe, the Espresso should be prepared in advance, so it has time to reach room temperature or colder in a fridge. If the Espresso were to be added to the tin while still hot, it would significantly increase the dilution from the ice.
		{'\n'}{'\n'}
		&emsp;2 ounces Vodka
		{'\n'}&emsp;1/2 ounce Coffee Liqueur
		{'\n'}&emsp;1 ounce Espresso
		{'\n'}&emsp;1/2 ounce Simple Syrup
		{'\n'}&emsp;Garnish with 3 Espresso Beans
		{'\n'}{'\n'}
		All ingredients are to be shaken over ice, and strained into a martini glass. Garnish with three espresso beans.
		{'\n'}{'\n'}
		<h1 style={{ textAlign: 'center' }}>The Clover Club</h1>
		{'\n'}{'\n'}
<img src="/blog_media/cocktail_blog_media/024.jpeg" alt="photo of the clover club" style={{ display: 'block', width: '50%', marginLeft: 'auto', marginRight: 'auto' }} />
		{'\n'}{'\n'}
		The Clover Club is a pre-prohibition cocktail from The United States, named for the Philadelphia men’s club with the same name in 1882. They met in the Bellevue-Stratford Hotel in Center City. I unfortunately did not have Raspberry Syrup when trying to make this cocktail but Chambord seemed to substitute well. Chambord is a brand of Raspberry Liqueur inspired by a liqueur from the Loire Valley of France, first in the 17th century, with an ABV of around 16%. It’s made with red and black raspberries along with Madagascar Vanilla, Moroccan Citrus Peel, Honey, and Cognac. The original in the 17th century was said to have been introduced to Louis XIV during one of his visits to the château de Chambord, hence the name.
		{'\n'}{'\n'}
		&emsp;1 and 1/2 ounce Gin
		{'\n'}&emsp;3/4 ounce Chambord
		{'\n'}&emsp;3/4 ounce Lemon Juice
		{'\n'}&emsp;1/4 ounce Simple Syrup
		{'\n'}&emsp;1 Egg White
		{'\n'}&emsp;Garnish with Raspberries.
		{'\n'}{'\n'}
		All ingredients are to be “dry” shaken without ice, then shaken with ice, strained into a coupe glass, and garnished raspberries.
		{'\n'}{'\n'}
		<h1 style={{ textAlign: 'center' }}>The Aperol Sour</h1>
		{'\n'}{'\n'}
<img src="/blog_media/cocktail_blog_media/025.jpeg" alt="photo of the aperol sour" style={{ display: 'block', width: '50%', marginLeft: 'auto', marginRight: 'auto' }} />
		{'\n'}{'\n'}
The Aperol Sour is a Sour cocktail. I cannot seem to find any information about the inventor or when the cocktail was first made.
		{'\n'}{'\n'}
		Aperol is a 1919 Italian red bitter liqueur, with ABV of 11%, made by the Barbieri brothers, Luigi and Silvio. It was based in Padua initially, a city in the Veneto Region of Italy. The name references the Italian slang for aperitivo which is “Apero”. It wasn’t popular until after World War II but has since become one of, if not, the most popular Amari, notably in the Aperol Spritz.
		{'\n'}{'\n'}
		&emsp;1 and 1/2 ounce Aperol
		{'\n'}&emsp;1/2 ounce Gin
		{'\n'}&emsp;3/4 ounce Lemon Juice
		{'\n'}&emsp;1/2 ounce Simple Syrup
		{'\n'}&emsp;1 Egg White
		{'\n'}&emsp;Garnish with Lemon Peel
		{'\n'}{'\n'}
		All ingredients are to be “dry” shaken without ice, then shaken with ice, strained into a coupe glass, and garnished with a lemon peel.
		{'\n'}{'\n'}
		<h1 style={{ textAlign: 'center' }}>The Fall Enzoni</h1>
		{'\n'}{'\n'}
<img src="/blog_media/cocktail_blog_media/026.jpeg" alt="photo of the fall enzoni" style={{ display: 'block', width: '50%', marginLeft: 'auto', marginRight: 'auto' }} />
		{'\n'}{'\n'}
		The Fall Enzoni is a modern cocktail that is a variation on Vincenzo Ericco’s Enzoni, made while he worked at the famed Milk &amp; Honey cocktail bar in New York in 2003. This riff was made by Brent Falco at Cole’s Bar in Chicago around 2012. It was initially on the menu there as a Garibaldi, until Leandro DiMonriva, another bartender there (and now the headman behind The Educated Barfly), realized the Garibaldi is a wholly different drink, and started calling it The Fall Enzoni.
		{'\n'}{'\n'}
		The original Enzoni is a sort of cross between a Negroni and a Gin Sour, using muddled green grapes instead of Vermouth. I had been making this cocktail with red grapes which were easier to come by, and found it quite nice. I had later discovered a variation on it called The Fall Enzoni which uses Whiskey instead of Gin and red grapes instead of green.
		{'\n'}{'\n'}
		&emsp;5 Grapes
		{'\n'}&emsp;1 ounce Rye Whiskey
		{'\n'}&emsp;1 ounce Campari
		{'\n'}&emsp;3/4 ounce Lemon Juice
		{'\n'}&emsp;3/4 ounce Simple Syrup
		{'\n'}&emsp;Garnish with 3 Grapes
		{'\n'}{'\n'}
		The grapes are to be added to the tin and muddled. All ingredients are to then be added, shaken over ice, and strained into a double rocks glass with a fresh large ice cube. Garnish with three red grapes.
		{'\n'}{'\n'}
		{'\n'}{'\n'}
      </p>
    </div>
    </div>
    </div>
  );
};

export default CocktailBlogPage;
